var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icons", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "We can add a representative icon inside our switch with the property "
        ),
        _c("code", [_vm._v("vs-icon")]),
        _vm._v("."),
      ]),
      _c("p", [
        _vm._v(
          "If you only need to add the icon in one of the states you can do it with the property "
        ),
        _c("code", [_vm._v("vs-icon-on")]),
        _vm._v(" or "),
        _c("code", [_vm._v("vs-icon-off")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v("Vuesax uses the "),
            _c("strong", [_vm._v("Google Material Icons")]),
            _vm._v(
              " font library. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page.")]
            ),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c("label", [_vm._v("Primary")]),
            _c("vs-switch", {
              attrs: {
                "icon-pack": "feather",
                "vs-icon-off": "icon-bell",
                "vs-icon-on": "icon-check",
              },
              model: {
                value: _vm.switch1,
                callback: function ($$v) {
                  _vm.switch1 = $$v
                },
                expression: "switch1",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Success")]),
            _c("vs-switch", {
              attrs: {
                color: "success",
                "icon-pack": "feather",
                "vs-icon": "icon-check",
              },
              model: {
                value: _vm.switch2,
                callback: function ($$v) {
                  _vm.switch2 = $$v
                },
                expression: "switch2",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Danger")]),
            _c("vs-switch", {
              attrs: {
                color: "danger",
                "icon-pack": "feather",
                "vs-icon": "icon-x",
              },
              model: {
                value: _vm.switch3,
                callback: function ($$v) {
                  _vm.switch3 = $$v
                },
                expression: "switch3",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Warning")]),
            _c("vs-switch", {
              attrs: {
                color: "warning",
                "icon-pack": "feather",
                "vs-icon": "icon-alert-circle",
              },
              model: {
                value: _vm.switch4,
                callback: function ($$v) {
                  _vm.switch4 = $$v
                },
                expression: "switch4",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Dark")]),
            _c("vs-switch", {
              attrs: {
                color: "dark",
                "icon-pack": "feather",
                "vs-icon": "icon-volume-x",
              },
              model: {
                value: _vm.switch5,
                callback: function ($$v) {
                  _vm.switch5 = $$v
                },
                expression: "switch5",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("rgb(color) / #color")]),
            _c("vs-switch", {
              attrs: {
                color: "rgb(51, 53, 83)",
                "icon-pack": "feather",
                "vs-icon": "icon-camera",
              },
              model: {
                value: _vm.switch6,
                callback: function ($$v) {
                  _vm.switch6 = $$v
                },
                expression: "switch6",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <ul class="demo-alignment">\n        <li>\n            <label>Primary</label>\n            <vs-switch v-model="switch1" icon-pack="feather" vs-icon-off="icon-bell" vs-icon-on="icon-check" />\n        </li>\n        <li>\n            <label>Success</label>\n            <vs-switch color="success" v-model="switch2" icon-pack="feather" vs-icon="icon-check" />\n        </li>\n        <li>\n            <label>Danger</label>\n            <vs-switch color="danger" v-model="switch3" icon-pack="feather" vs-icon="icon-x" />\n        </li>\n        <li>\n            <label>Warning</label>\n            <vs-switch color="warning" v-model="switch4" icon-pack="feather" vs-icon="icon-alert-circle" />\n        </li>\n        <li>\n            <label>Dark</label>\n            <vs-switch color="dark" v-model="switch5" icon-pack="feather" vs-icon="icon-volume-x" />\n        </li>\n        <li>\n            <label>rgb(color) / #color</label>\n            <vs-switch color="rgb(51, 53, 83)" v-model="switch6" icon-pack="feather" vs-icon="icon-camera" />\n        </li>\n    </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      switch1:false,\n      switch2:false,\n      switch3:false,\n      switch4:false,\n      switch5:false,\n      switch6:false\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }