var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "State", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To implement a switch element in the application, we add the component "
        ),
        _c("code", [_vm._v("vs-switch")]),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c("label", [_vm._v("true / active")]),
            _c("vs-switch", {
              model: {
                value: _vm.switch1,
                callback: function ($$v) {
                  _vm.switch1 = $$v
                },
                expression: "switch1",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("false / inactive")]),
            _c("vs-switch", {
              model: {
                value: _vm.switch2,
                callback: function ($$v) {
                  _vm.switch2 = $$v
                },
                expression: "switch2",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("disabled / active")]),
            _c("vs-switch", {
              attrs: { disabled: "true" },
              model: {
                value: _vm.switch3,
                callback: function ($$v) {
                  _vm.switch3 = $$v
                },
                expression: "switch3",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("disabled / inactive")]),
            _c("vs-switch", {
              attrs: { disabled: "true" },
              model: {
                value: _vm.switch4,
                callback: function ($$v) {
                  _vm.switch4 = $$v
                },
                expression: "switch4",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <ul class="switch-container">\n    <li>\n      <label>true / active</label>\n      <vs-switch v-model="switch1" />\n    </li>\n    <li>\n      <label>false / inactive</label>\n      <vs-switch v-model="switch2" />\n    </li>\n    <li>\n      <label>disabled / active</label>\n      <vs-switch disabled="true" v-model="switch3" />\n    </li>\n    <li>\n      <label>disabled / inactive</label>\n      <vs-switch disabled="true" v-model="switch4" />\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      switch1:true,\n      switch2:false,\n      switch3:true,\n      switch4:false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }