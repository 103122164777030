var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("You can choose the default "),
        _c("strong", [_vm._v("Switch")]),
        _vm._v(". You are able to use the Main Colors or "),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v(" colors."),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c("label", [_vm._v("Primary")]),
            _c("vs-switch", {
              model: {
                value: _vm.switch1,
                callback: function ($$v) {
                  _vm.switch1 = $$v
                },
                expression: "switch1",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Success")]),
            _c("vs-switch", {
              attrs: { color: "success" },
              model: {
                value: _vm.switch2,
                callback: function ($$v) {
                  _vm.switch2 = $$v
                },
                expression: "switch2",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Danger")]),
            _c("vs-switch", {
              attrs: { color: "danger" },
              model: {
                value: _vm.switch3,
                callback: function ($$v) {
                  _vm.switch3 = $$v
                },
                expression: "switch3",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Warning")]),
            _c("vs-switch", {
              attrs: { color: "warning" },
              model: {
                value: _vm.switch4,
                callback: function ($$v) {
                  _vm.switch4 = $$v
                },
                expression: "switch4",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("Dark")]),
            _c("vs-switch", {
              attrs: { color: "dark" },
              model: {
                value: _vm.switch5,
                callback: function ($$v) {
                  _vm.switch5 = $$v
                },
                expression: "switch5",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "con-input-color" },
          [
            _c("label", [
              _vm._v("Color: "),
              _c("span", [_vm._v(_vm._s(_vm.color))]),
            ]),
            _c("vs-switch", {
              attrs: { color: _vm.color },
              model: {
                value: _vm.switch6,
                callback: function ($$v) {
                  _vm.switch6 = $$v
                },
                expression: "switch6",
              },
            }),
          ],
          1
        ),
        _c("li", { staticStyle: { "align-self": "flex-end" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.color,
                expression: "color",
              },
            ],
            attrs: { type: "color" },
            domProps: { value: _vm.color },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.color = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <ul class="con-s">\n    <li>\n      <label>Primary</label>\n      <vs-switch v-model="switch1" />\n    </li>\n    <li>\n      <label>Success</label>\n      <vs-switch color="success" v-model="switch2" />\n    </li>\n    <li>\n      <label>Danger</label>\n      <vs-switch color="danger" v-model="switch3" />\n    </li>\n    <li>\n      <label>Warning</label>\n      <vs-switch color="warning" v-model="switch4" />\n    </li>\n    <li>\n      <label>Dark</label>\n      <vs-switch color="dark" v-model="switch5" />\n    </li>\n    <li class="con-input-color">\n      <label>Color: <span>' +
            _vm._s(_vm.color) +
            '</span></label>\n      <input v-model="color" type="color">\n      <vs-switch :color="color" v-model="switch6" />\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      color:\'#5a3cc4\',\n      switch1:true,\n      switch2:true,\n      switch3:true,\n      switch4:true,\n      switch5:true,\n      switch6:true,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }