var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Array Value", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "For saving the values in an array, you could simply pass it as a value."
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("For using an array as a value, we need to use the "),
            _c("code", [_vm._v("vs-value")]),
            _vm._v(
              " property inside the switch component. That is the value that will be added to the array."
            ),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: {
                  "vs-icon-on": "check",
                  color: "success",
                  "vs-value": "luis",
                },
                model: {
                  value: _vm.switchArray,
                  callback: function ($$v) {
                    _vm.switchArray = $$v
                  },
                  expression: "switchArray",
                },
              },
              [
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Luis"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: {
                  "vs-icon-on": "check",
                  color: "success",
                  "vs-value": "carols",
                },
                model: {
                  value: _vm.switchArray,
                  callback: function ($$v) {
                    _vm.switchArray = $$v
                  },
                  expression: "switchArray",
                },
              },
              [
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Carols"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: {
                  "vs-icon-on": "check",
                  color: "success",
                  "vs-value": "summer",
                },
                model: {
                  value: _vm.switchArray,
                  callback: function ($$v) {
                    _vm.switchArray = $$v
                  },
                  expression: "switchArray",
                },
              },
              [
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Summer"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: {
                  "vs-icon-on": "check",
                  color: "success",
                  "vs-value": "lyon",
                },
                model: {
                  value: _vm.switchArray,
                  callback: function ($$v) {
                    _vm.switchArray = $$v
                  },
                  expression: "switchArray",
                },
              },
              [
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Lyon"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "op-block mt-5" }, [
        _vm._v(_vm._s(_vm.switchArray)),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="con-s">\n    <li class="modelx">\n      ' +
            _vm._s("{{ switchArray }}") +
            '\n    </li>\n    <li>\n      <vs-switch vs-icon-on="check" color="success" v-model="switchArray" vs-value="luis">\n        <span slot="off">Luis</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch vs-icon-on="check" color="success" v-model="switchArray" vs-value="carols">\n        <span slot="off">Carols</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch vs-icon-on="check" color="success" v-model="switchArray" vs-value="summer">\n        <span slot="off">Summer</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch vs-icon-on="check" color="success" v-model="switchArray" vs-value="lyon">\n        <span slot="off">Lyon</span>\n      </vs-switch>\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      switchArray:[\'luis\'],\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }