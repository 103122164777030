var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-element-switch-demo" } },
    [
      _c("switch-state"),
      _c("switch-color"),
      _c("switch-text"),
      _c("switch-icons"),
      _c("switch-array-value"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }