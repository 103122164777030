var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Text", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("You can add a descriptive text with the slot "),
        _c("code", [_vm._v("on")]),
        _vm._v(" or "),
        _c("code", [_vm._v("off")]),
        _vm._v(". You can also join the text with the icons."),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                model: {
                  value: _vm.switch1,
                  callback: function ($$v) {
                    _vm.switch1 = $$v
                  },
                  expression: "switch1",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("On"),
                ]),
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Off"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: { color: "success" },
                model: {
                  value: _vm.switch2,
                  callback: function ($$v) {
                    _vm.switch2 = $$v
                  },
                  expression: "switch2",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("Accept"),
                ]),
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Cancel"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: { color: "danger", "vs-icon-off": "close" },
                model: {
                  value: _vm.switch3,
                  callback: function ($$v) {
                    _vm.switch3 = $$v
                  },
                  expression: "switch3",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("Remove"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: { color: "warning", "vs-icon-on": "error_outline" },
                model: {
                  value: _vm.switch4,
                  callback: function ($$v) {
                    _vm.switch4 = $$v
                  },
                  expression: "switch4",
                },
              },
              [
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Prevent"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-switch",
              {
                attrs: {
                  color: "dark",
                  "icon-pack": "feather",
                  "vs-icon-on": "icon-check-circle",
                  "vs-icon-off": "icon-slash",
                },
                model: {
                  value: _vm.switch5,
                  callback: function ($$v) {
                    _vm.switch5 = $$v
                  },
                  expression: "switch5",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("YES"),
                ]),
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("NO"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="con-s">\n    <li>\n      <vs-switch v-model="switch1">\n        <span slot="on">On</span>\n        <span slot="off">Off</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch color="success" v-model="switch2">\n        <span slot="on">Accept</span>\n        <span slot="off">Cancel</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch color="danger" vs-icon-off="close" v-model="switch3">\n        <span slot="on">Remove</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch color="warning" vs-icon-on="error_outline" v-model="switch4">\n        <span slot="off">Prevent</span>\n      </vs-switch>\n    </li>\n    <li>\n      <vs-switch color="dark" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="switch5">\n        <span slot="on">YES</span>\n        <span slot="off">NO</span>\n      </vs-switch>\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      switch1:true,\n      switch2:true,\n      switch3:true,\n      switch4:true,\n      switch5:true,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }